import React from 'react';
import {navigate} from 'gatsby';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import visual from '../../images/monitoringScreensSmall/visual.jpg';
import htmlElement from '../../images/monitoringScreensSmall/html-element.jpg';
import htmlElementAdvanced from '../../images/monitoringScreensSmall/html-element-advanced.jpg';
import keyword from '../../images/monitoringScreensSmall/keyword.jpg';
import technology from '../../images/monitoringScreensSmall/tech.jpg';
import html_1 from '../../images/monitoringScreensSmall/html.jpg';
import availability from '../../images/monitoringScreensSmall/availability.jpg';
import automaticAI from '../../images/monitoringScreensSmall/ai.jpg';
import domainWhois from '../../images/monitoringScreensSmall/whoIs.jpg';
import playIcon from '../../images/monitoringScreensSmall/play-btn.png';
import Tabs from '../../components/tabs';
import './styles.scss';

const data = [
  {image: visual, text: 'Visual monitor Walkthrough', linkTo: '/visual-monitoring-walkthrough'},
  {image: htmlElement, text: 'HTML element monitor Walkthrough', linkTo: '/html-element-monitoring-walkthrough'},
  {
    image: htmlElementAdvanced,
    text: 'HTML element monitor Advanced Walkthrough',
    linkTo: '/html-element-advanced-monitoring-walkthrough',
  },
  {image: keyword, text: 'Keyword monitor Walkthrough', linkTo: '/keyword-monitoring-walkthrough'},
  {image: technology, text: 'Technology monitor Walkthrough', linkTo: '/technology-monitoring-walkthrough'},
  {image: html_1, text: 'Source code monitor Walkthrough', linkTo: '/html-monitoring-walkthrough'},
  // {image: automaticAI, text: 'Automatic AI monitor Walkthrough', linkTo: '/automatic-ai-monitoring-walkthrough'},
  {image: availability, text: 'Availability monitor Walkthrough', linkTo: '/availability-monitoring-walkthrough'},
  {image: domainWhois, text: 'Domain WHOIS monitor Walkthrough', linkTo: '/domain-whois-monitoring-walkthrough'},
];

const HowToUseHexowatch = () => {
  return (
    <Layout>
      <PageHero
        title="How to use Hexowatch"
        subtitle="Need help implementing hexowatch in your business? Get started fast with our walkthrough videos that explain how to setup
        all our different monitor types."
      />
      <Section>
        <div style={{margin: 'auto'}}>
          <Tabs />
        </div>
        <div className="col-12 d-block mb_20 how-to-use-hexowatch" style={{maxWidth: '1200px', width: '100%'}}>
          <div className="row">
            {data.map(item => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <div className="card" onClick={() => navigate(item.linkTo)}>
                  <div className="img-block">
                    <img src={item.image} className="bg-img" alt="" />
                    <img src={playIcon} className="play-icon" alt="" />
                  </div>
                  <div className="text-block text-center">
                    <h4>{item.text}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default HowToUseHexowatch;
